<template>
  <div>
    <div class="d-none d-xl-block left-aside-menu">
      <b-row >
        <b-col md="3">
          <slot name="menu">
            <side-bar-menu class="container" :title="menuTitle">
              <slot></slot>
            </side-bar-menu>
          </slot>
        </b-col>
        <b-col md="9">
          <router-view></router-view>
        </b-col>
      </b-row>
    </div>

    <div class="d-block d-xl-none">
      <b-button variant="ffvlOrangePicto" v-b-toggle.sidebar-menu class="float-left " style="margin-right:10px; margin-top:-20px;">
        <b-icon icon="chevron-right" variant="light" />
      </b-button>

        <router-view style="margin-left:10px;"></router-view>

      <b-sidebar id="sidebar-menu"
        :title="menuTitle"
        shadow
        :bg-variant="bgVariant"
        :text-variant="textVariant"
        sidebar-class="border-right border-danger"
        :lazy="lazy"
      >
        <ul>
        <slot></slot>
        </ul>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import SideBarMenu from '@/components/Panels/SideBarMenu.vue';

export default{
  components: { SideBarMenu },
  props:{
    menuTitle: { type:String, default:'' },
    lazy: Boolean,
    bgVariant: { type:String, default:'dark'},
    textVariant: { type:String, default: 'light'},
    buttonVariant: { type:String, default: 'primary'},
  }
}
</script>


