<template>
  <left-aside-menu 
      :menuTitle = "menuTitle"
      :lazy = "lazy"
      :bgVariant = "bgVariant"
      :textVariant = "textVariant"
      :buttonVariant = "buttonVariant"
  >
      <!-- Accueil -->
    <side-bar-menu-link routeName="Home" :to="{name:'Home'}" icon="house-door">Accueil</side-bar-menu-link>

<!--TODEL?    <competition-section>
    </competition-section>-->

  <!--
    <side-bar-menu-group-item title="Compétitions (en cours, à venir)" :operations="securityMap.competitionsManagement_group_operations" >
        <side-bar-menu-link :to="{name:'CompetitionCreate'}" :operations="securityMap.create_competition_operations">Nouvelle compétition</side-bar-menu-link>
        <side-bar-menu-link :to="{name:'CompetitionsRunning'}" :operations="securityMap.existingCompetitions_operations">Compétitions en cours</side-bar-menu-link>
        <side-bar-menu-link :to="{name:'CompetitionsRunning'}" v-if="userCanOneOf(securityMap.existingCompetitions_operations) || hasUserRunningCompetitionsAsync()">Compétitions en cours</side-bar-menu-link>
    </side-bar-menu-group-item> -->
    <side-bar-menu-group-item title="Compétitions (en cours, à venir)" v-if="userCanOneOf(securityMap.competitionsManagement_group_operations) || hasUserRunningCompetitionsAsync()" >
        <side-bar-menu-link :to="{name:'CompetitionCreate'}" :operations="securityMap.create_competition_operations">Nouvelle compétition</side-bar-menu-link>
        <side-bar-menu-link :to="{name:'CompetitionsRunning'}" v-if="userCanOneOf(securityMap.existingCompetitions_operations) || hasUserRunningCompetitionsAsync()">Compétitions en cours</side-bar-menu-link>
    </side-bar-menu-group-item>
<!--TODEL?    <side-bar-menu-group-item title="Compétitions (en cours, à venir)">
      <create-competition-action>
        <side-bar-menu-link :to="{name:'CompetitionCreate'}">Nouvelle compétition</side-bar-menu-link>
      </create-competition-action> 
      <running-competition-list-action>
        <side-bar-menu-link :to="{name:'CompetitionsRunning'}">Compétitions en cours</side-bar-menu-link>
      </running-competition-list-action>
      <slot name="competitions-section"></slot>
    </side-bar-menu-group-item>
-->

    <side-bar-menu-link v-if="false" routeName="Results-Default" icon="award-fill" text="Résultats" title="Résultats des compétitions terminées">Résultats</side-bar-menu-link>

    <web-area>
      <side-bar-menu-link routeName="Downloads" icon="download" text="Téléchargements" title="Téléchargements de l'application mobile">Téléchargements</side-bar-menu-link>
    </web-area>

<!--TODEL?    <administration-section></administration-section>-->

    <side-bar-menu-group-item title="Administration" :operations="securityMap.administration_group_operations">
        <side-bar-menu-link :to="{name:'StaffListAdmin'}" ref="staffAdmin" authenticationRequired :operations="securityMap.staffAdmin_operations">Juges &amp; staff</side-bar-menu-link>
        <side-bar-menu-link ref="competitorAdmin" v-if="false" :operations="securityMap.competitorAdmin_operations">Compétiteurs</side-bar-menu-link>
        <side-bar-menu-group-item title="Données de référentiel" ref="referentialDataAdmin" :operations="securityMap.referentialDataAdmin_operations">
            <side-bar-menu-link :to="{name:'MasterDataGlobalView'}" ref="masterDataGlobalView" authenticationRequired :operations="securityMap.masterDataGlobalView_operations">Vue globale</side-bar-menu-link>
        </side-bar-menu-group-item>
        <side-bar-menu-group-item title="Saison" :operations="securityMap.seasonAdmin_group_operations">
            <side-bar-menu-link :to="{name: 'SeasonCompulsoriesAdmin'}" ref="seasonCompulsoriesAdmin" authenticationRequired :operations="securityMap.seasonAdmin_compulsories_operations">Figures de l'année</side-bar-menu-link>
        </side-bar-menu-group-item>
    </side-bar-menu-group-item>

    <side-bar-menu-group-item title="Sécurité" :operations="securityMap.security_group_operations">
      <side-bar-menu-link :to="{name: 'SecurityUsersList'}" :operations="securityMap.securityUsers_operations">Utilisateurs</side-bar-menu-link>
      <side-bar-menu-link :to="{name: 'SecurityRolesList'}" :operations="securityMap.securityRoles_operations">Roles</side-bar-menu-link>
      <side-bar-menu-link :to="{name: 'SecurityOperationsList'}" :operations="securityMap.securityOperations_operations">Opérations</side-bar-menu-link>
    </side-bar-menu-group-item>

    <side-bar-menu-group-item title="Outils" :operations="securityMap.tools_group_operations">
      <side-bar-menu-link :to="{name:'TestApi'}" :operations="securityMap.testApi_operations">Test d'appel ajax ver Api</side-bar-menu-link>
      <side-bar-menu-group-item :to="{name: 'GraphicalCharter'}" :operations="securityMap.graphicalCharter_operations" title="Charte graphique" >
          <side-bar-menu-link :to="{name: 'GraphicalCharter', hash: '#Formulaires'}">Formulaires</side-bar-menu-link>
          <side-bar-menu-link :to="{name: 'GraphicalCharter', hash: '#Champs'}">Champs de Formulaire</side-bar-menu-link>
      </side-bar-menu-group-item>
      <side-bar-menu-link :to="{name: 'JsTips'}" :operations="securityMap.jsTips">Javascript tips</side-bar-menu-link>
      <side-bar-menu-link :to="{name: 'DevUsefulLinks'}" :operations="securityMap.devUsefulLinks">Liens utiles</side-bar-menu-link>
    </side-bar-menu-group-item>

    <side-bar-menu-link :to="{name:'ConfigurationHome'}">Configuration</side-bar-menu-link>

  </left-aside-menu>
</template>

<script>
import { mapActions } from "vuex";
import LeftAsideMenu from "@/components/PageTemplates/LeftAsideMenu.vue"
//import SideBarMenu from '@/components/Panels/SideBarMenu.vue';
import SideBarMenuGroupItem from '@/components/Panels/SideBarMenuGroupItem.vue';
import SideBarMenuLink from '@/components/Panels/SideBarMenuLink.vue';
import WebArea from '../WebArea.vue';
//import CreateCompetitionAction from './LeftAsideMenuSections/CreateCompetitionAction.vue';
//import CompetitionSection from './LeftAsideMenuSections/CompetitionSection.vue';
//import AdministrationSection from './LeftAsideMenuSections/AdministrationSection.vue';
//import SecuredArea from "@/components/Security/NewSecuredArea.vue";
import SecurityMap from "@/Constants/securityMap.js";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';

export default{
  components: { LeftAsideMenu/*, SideBarMenu, SecuredArea*/, SideBarMenuGroupItem, SideBarMenuLink, WebArea, /*CreateCompetitionAction,  CompetitionSection, AdministrationSection*/ },
  props: {
    menuTitle: { type:String, default:'Menu' },
    lazy: Boolean,
    bgVariant: { type:String, default:'dark'},
    textVariant: { type:String, default: 'light'},
    buttonVariant: { type:String, default: 'primary'},
  },
  data(){
    return {
    }
  },
  computed:{
    securityMap(){ return SecurityMap; },
  },
  methods:{
    ...mapActions('auth', ['userCanOneOf'] ),
    async hasUserRunningCompetitionsAsync(){
      return await CompetitionsConfigurationHelper.hasUserRunningCompetitionsAsync(this.isTestMode);
    }
  }
}
</script>


